import logo from "./logo.svg";
import "./App.css";
import Background1 from "./images/living_room_decor.jpg";
import Background2 from "./images/living_room_decor_1.jpg";
import Background3 from "./images/living_room_decor_2.jpg";
import BackgroundSlider from "react-background-slider";

function App() {
  return (
    <div className="App">
      <BackgroundSlider
        images={[Background1, Background2, Background3]}
        duration={10}
        transition={2}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: '100vh',
          background:
            "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
        }}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', color: "white", height: '100%'}}>
          <h1 style={{letterSpacing: '2px'}}>CRIBS2LIFE</h1>
          <h1 style={{letterSpacing: '3px', fontSize: '60px', fontWeight: 'normal', paddingTop: '200px'}}>Launching Soon...</h1>
        </div>
        
      </div>
    </div>
  );
}

export default App;
